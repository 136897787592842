import React, {Fragment} from 'react'


const Image = ({name, className, onClick= null}) => {
    const image = require(`assets/${name}`);

   return (
       <Fragment>
            <img src={image} className={className} onClick={onClick} alt="..." />
       </Fragment>
   );
}

export default Image;