import axios from "axios";
import { message } from "antd";

const SECONDS = 30;
const MILLISECONDS = 1000;
const TIMEOUT = SECONDS * MILLISECONDS;
const REACT_APP_BASE_API = process.env.REACT_APP_BASE_API;
export const fileDownloadBaseUrl = REACT_APP_BASE_API+"/Common/downloadFile?filePath=";
export const fileViewBaseUrl = REACT_APP_BASE_API+"/Common/getFile?filePath=";


const client = axios.create({
        baseURL: REACT_APP_BASE_API,
        timeout: TIMEOUT,
        headers: {
            "content-type": "application/json",
        }
});

client.interceptors.request.use(function (config) {
    return config;
});

client.interceptors.response.use(
    function (response) {
        return response;
    },
    function (error) {
        if(error?.response?.status === 400) {
            message.error(error?.response?.data);
        } else if (error.response.data) {
            message.error(error?.response?.data?.message);
        }
        return Promise.reject(error);
    }
);

const getPermission = async (formData) => {
    const { data } = await client.post(`Role/GetRolePermissions`, formData);
    return data;
}

export {
    getPermission,
}