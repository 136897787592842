import React, { Fragment } from "react";
import "./style.css"

const NotFound = () => {
    return (
        <Fragment>
            <div className="error-page-main">
            <div>
                <div class="starsec"></div>
                <div class="starthird"></div>
                <div class="starfourth"></div>
                <div class="starfifth"></div>
            </div>

            <div class="lamp__wrap">
                <div class="lamp">
                    <div class="cable"></div>
                    <div class="cover"></div>
                    <div class="in-cover">
                        <div class="bulb"></div>
                    </div>
                    <div class="light"></div>
                </div>
            </div>
            <section class="error">
                <div class="error__content">
                    <div class="error__message message">
                        <p class="message__title">404</p>
                        <p class="message__title">Page Not Found</p>
                    </div>
                    <div class="error__messages">
                        <p class="message__text">We're sorry, the page you were looking for isn't found here. The link you followed may either be broken or no longer exists. Please try again</p>
                    </div>
                </div>

            </section>
            </div>


        </Fragment>

    )
}
export default NotFound