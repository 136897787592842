import Image from "helpers/Image";
import React, { Fragment } from "react";

const UserNotFound = () => {
    return (
        <Fragment>
            <div className="not-found">
                <Image name="user_not_found.jpg" />
            </div>
        </Fragment>

    )
}
export default UserNotFound