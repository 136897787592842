import React, { Fragment, useState, useContext } from 'react';
import { Link, useNavigate, useLocation, useParams } from 'react-router-dom';
import { fileViewBaseUrl } from 'services/common/common';
import { Row, Col, Avatar, Layout, Menu } from 'antd';
import permissionContext from 'provider/permission';
import Image from 'helpers/Image';
import "./style.css"

const { Sider } = Layout;

const CommonSidebar = () => {
  const rolePermissions = useContext(permissionContext);
  let navigate = useNavigate();
  let location = useLocation();
  const eqid = useParams();

  const [collapsed, setCollapsed] = useState(true);
  const [currentMenu, setCurrentMent] = useState("dashboard");

  const handleClick = (val) => {
    setCurrentMent(val.key);
    if (val?.key === "dashboard") {
      navigate("/calibration/report-performance/dashboard");
    } else if (val?.key === "trend") {
      navigate("/calibration/report-performance/trend");
    } else if (val?.key === "report") {
      navigate("/calibration/report-performance/report");
    } else if (val?.key === 'submitTask') {
      navigate("/calibration/task-submission/submit-task-completion");
    } else if (val?.key === 'rejectedTask') {
      navigate("/calibration/task-submission/rejected-task");
    } else if (val?.key === '3') {
      navigate("/calibration/task-certification");
    } else if (val?.key === 'notification') {
      navigate("/calibration/system-configuration/notification-management");
    } else if (val?.key === 'activity') {
      navigate("/calibration/system-configuration/activity-log");
    } else if (val?.key === 'user') {
      navigate("/calibration/system-configuration/user-management");
    } else if (val?.key === 'task') {
      navigate("/calibration/system-configuration/task-management");
    } else if (val?.key === 'equipment') {
      navigate("/calibration/system-configuration/equipment-management/active");
    } else if (val?.key === 'shedule') {
      navigate("/calibration/system-configuration/schedule-management");
    } else if (val?.key === 'template') {
      navigate("/calibration/system-configuration/report-template-version");
    }
  }

  const selectedKey = (path) => {
    if (path === "/" || path === "/calibration/report-performance/dashboard") {
      return "dashboard"
    } else if (path === "/calibration/report-performance/trend") {
      return "trend"
    } else if ((path === "/calibration/report-performance/report" || path === `/calibration/report-performance/report/${eqid?.id}`)) {
      return "report"
    } else if (path === "/calibration/task-submission/submit-task-completion") {
      return "submitTask"
    } else if (path === "/calibration/task-submission/rejected-task") {
      return "rejectedTask"
    } else if (path === "/calibration/task-certification") {
      return "3"
    } else if (path === "/calibration/system-configuration/notification-management") {
      return "notification"
    } else if (path === "/calibration/system-configuration/activity-log") {
      return "activity"
    } else if (path === "/calibration/system-configuration/user-management") {
      return "user"
    } else if (path === "/calibration/system-configuration/task-management") {
      return "task"
    } else if (path === "/calibration/system-configuration/equipment-management/active" || path === "/calibration/system-configuration/equipment-management/inactive") {
      return "equipment"
    } else if (path === "/calibration/system-configuration/schedule-management") {
      return "shedule"
    } else if (path === "/calibration/system-configuration/report-template-version") {
      return "template"
    }
  }

  function getItem(label, key, icon, children) {
    return {
      key,
      icon,
      children,
      label,
    };
  }

  const reportSubNames = ["dashboard", "trend", "report"];
  const SubmissionSubNames = ["submitTask", "rejectedTask"];
  const ConfigurationSubNames = ["notification", "activity", "user", "task", "equipment", "shedule", "template"];


  const items =rolePermissions&& [
    // Report & Performance
    (rolePermissions.permission_Models[11].actionModel.isView || rolePermissions.permission_Models[10].actionModel.isView || rolePermissions.permission_Models[0].actionModel.isView) ? getItem('Report & Performance', '1', <Image name={reportSubNames.includes(selectedKey(location?.pathname)) ? "clipboard-report-white.svg" : "clipboard-report.svg"} className="sidebar-tab-icon" />, [
      rolePermissions.permission_Models[0].actionModel.isView ? getItem('Dashboard', 'dashboard', <Image name={currentMenu === "dashboard" ? "dashboard-white.svg" : "dashboard.svg"} className="sidebar-tab-icon" />) : null,
      rolePermissions.permission_Models[10].actionModel.isView ? getItem('Trend Chart', 'trend', <Image name={currentMenu === "trend" ? "analysis-trend-white.svg" : "analysis-trend.svg"} className="sidebar-tab-icon" />) : null,
      rolePermissions.permission_Models[11].actionModel.isView ? getItem('Reporting', 'report', <Image name={currentMenu === "report" ? "record-white.svg" : "record.svg"} className="sidebar-tab-icon" />) : null,
    ]) : null,

    // Tasks Submission
    (rolePermissions.permission_Models[12].actionModel.isView || rolePermissions.permission_Models[13].actionModel.isView) ? getItem('Tasks Submission', '2', <Image name={SubmissionSubNames.includes(selectedKey(location?.pathname)) ? "tasks-list-white.svg" : "tasks-list.svg"} className="sidebar-tab-icon" />, [
      rolePermissions.permission_Models[12].actionModel.isView ? getItem('Submit Tasks Completion', 'submitTask', <Image name={currentMenu === "1" ? "dashboard-task.svg" : "dashboard-task.svg"} className="sidebar-tab-icon" />) : null,
      rolePermissions.permission_Models[13].actionModel.isView ? getItem('Review Rejected Task', 'rejectedTask', <Image name={currentMenu === "1" ? "dashboard-task.svg" : "dashboard-task.svg"} className="sidebar-tab-icon" />) : null,
    ]) : null,

    // Task Certification
    rolePermissions.permission_Models[14].actionModel.isView ? getItem("Task Certification", '3', <Image name={selectedKey(location?.pathname) === "3" ? "certificate-white.svg" : "certificate.svg"} className="sidebar-tab-icon" />) : null,

    // 
    (rolePermissions.permission_Models[4].actionModel.isView || rolePermissions.permission_Models[15].actionModel.isView
      || rolePermissions.permission_Models[1].actionModel.isView || rolePermissions.permission_Models[5].actionModel.isView
      || rolePermissions.permission_Models[6].actionModel.isView || rolePermissions.permission_Models[7].actionModel.isView
      || rolePermissions.permission_Models[8].actionModel.isView) ? getItem('System Configuration', '4', <Image name={ConfigurationSubNames.includes(selectedKey(location?.pathname)) ? "tasks-list-white.svg" : "settings.svg"} className="sidebar-tab-icon" />, [
        rolePermissions.permission_Models[4].actionModel.isView ? getItem('Notification Management', 'notification', <Image name={currentMenu === "notification" ? "dashboard-settings-white.svg" : "dashboard-settings.svg"} className="sidebar-tab-icon" />) : null,
        rolePermissions.permission_Models[15].actionModel.isView ? getItem('Activity Log', 'activity', <Image name={currentMenu === "activity" ? "activity-log-white.svg" : "activity-log.svg"} className="sidebar-tab-icon" />) : null,
        rolePermissions.permission_Models[1].actionModel.isView ? getItem('User Management', 'user', <Image name={currentMenu === "user" ? "dashboard-user-white.svg" : "dashboard-user.svg"} className="sidebar-tab-icon" />) : null,
        rolePermissions.permission_Models[5].actionModel.isView ? getItem('Task Management', 'task', <Image name={currentMenu === "1" ? "dashboard-task.svg" : "dashboard-task.svg"} className="sidebar-tab-icon" />) : null,
        rolePermissions.permission_Models[6].actionModel.isView ? getItem('Equipment Management', 'equipment', <Image name={currentMenu === "equipment" ? "dashboard-equipment-white.svg" : "dashboard-equipment.svg"} className="sidebar-tab-icon" />) : null,
        rolePermissions.permission_Models[7].actionModel.isView ? getItem('Schedule Management', 'shedule', <Image name={currentMenu === "1" ? "dashboard-shedule.svg" : "dashboard-shedule.svg"} className="sidebar-tab-icon" />) : null,
        rolePermissions.permission_Models[8].actionModel.isView ? getItem('Report Template Version', 'template', <Image name={currentMenu === "template" ? "record-white.svg" : "record.svg"} className="sidebar-tab-icon" />) : null,
      ]) : null,
  ];


  return (
    <Fragment>
      <Sider trigger={null} className={collapsed ? "sidebar-small" : "sidebar-big"} collapsible collapsed={collapsed} onMouseEnter={() => setCollapsed(false)}
        onMouseLeave={() => setCollapsed(true)} >
        <div className={collapsed ? "sidebar-logo-small txt-center" : "sidebar-logo-big txt-center"}>
          <Link to="/">
            <Image name={collapsed ? "small-logo.png" : "sidebar-logo.png"} className={collapsed ? "small-logo" : "logo"} />
          </Link>
        </div>
        <Menu
          theme="dark"
          mode="inline"
          className='sidebar-menu-item'
          defaultSelectedKeys={['1']}
          onClick={handleClick}
          selectedKeys={selectedKey(location?.pathname)}
          items={items}
        />
        <br /><br /><br /><br />
        <div className={collapsed ? "sidebar-Footer-small" : "sidebar-Footer-big"}>
          <div className='profile-details'>
            <Row >
              <Col md={6}>
                {rolePermissions?.profile_image_url === null ? <Avatar size={42}>{rolePermissions?.user_name.charAt(0).toUpperCase()}</Avatar> : <Avatar src={`${fileViewBaseUrl}${encodeURIComponent(rolePermissions?.profile_image_url)}`} size={42}></Avatar>}
              </Col>
              <Col md={17}>
                <p>{rolePermissions?.user_name}</p>
                <h3>{rolePermissions?.role_name}</h3>
              </Col>
            </Row>
          </div>
        </div>
      </Sider>
    </Fragment >
  )
}

export default CommonSidebar
