import React, { lazy, Suspense, useState, useEffect } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import permissionContext from "provider/permission";
import { useSearchParams, useNavigate } from 'react-router-dom';
import { useGetPermission } from "hooks/common/common";
import NotFound from "views/shared-component/404";
import { message } from "antd";
import UserNotFound from "views/shared-component/usernotFound";
import Image from "helpers/Image";

// Layouts
import AppLayout from "layouts/CommonLayout";


//auth
const Login = lazy(() => import("./views/auth/login"));
const SignUp = lazy(() => import("./views/auth/register"));
const Dashboard = lazy(() => import("./views/auth/dashboard"));
const Nfc = lazy(() => import("./views/auth/nfc"));

// Report&Performance Routes
// const Dashboard = lazy(() => import("../src/views/app/report&performance/dashboard/index"));
const Trend = lazy(() => import("../src/views/app/report&performance/trend/index"));
const Reporting = lazy(() => import("../src/views/app/report&performance/record/index"));
const Detailedreport = lazy(() => import("../src/views/app/report&performance/record/components/calibrationRecords"));

// Task Submission Routes
const CompletedTask = lazy(() => import("../src/views/app/taskSubmission/listing/completion"));
const RejectTask = lazy(() => import("../src/views/app/taskSubmission/listing/rejected"));

// Task Certification Routes
const TaskCertification = lazy(() => import("../src/views/app/taskCertification/index"));

// ststem Configuration
const ActivityLog = lazy(() => import("./views/app/systemConfiguration/activityLog"))
const UserManagement = lazy(() => import("./views/app/systemConfiguration/userManagement"));
const DepartmentManagement = lazy(() => import("./views/app/systemConfiguration/userManagement/department"));
const TaskManagement = lazy(() => import("../src/views/app/systemConfiguration/taskManagement/index"));
const ActiveEquipment = lazy(() => import("../src/views/app/systemConfiguration/equipmentManagement/listing/active"));
const InactiveEquipment = lazy(() => import("../src/views/app/systemConfiguration/equipmentManagement/listing/inactive"));
const ScheduleManagement = lazy(() => import("../src/views/app/systemConfiguration/calibrationSchedule/index"));
const NotificationManagement = lazy(() => import("../src/views/app/systemConfiguration/notificationManagement/index"));
const TemplateVersion = lazy(() => import("./views/app/systemConfiguration/templateVersion/index"));


function App() {
  // const [searchParams] = useSearchParams();
  // let navigate = useNavigate();

  // // const employee_email = searchParams.get("customer_email");
  // const auth = searchParams.get("auth_code");

  const [rolePermission, setRolePermission] = useState(null);
  // const [initial, setinitial] = useState(false);

  const { mutate: getRolePermintion, isLoading: loading } = useGetPermission();

  // const fetchPermissionDetails = () => {
  //   const payload = {
  //     "role_Id": 0,
  //     "email": localStorage?.getItem("Email")
  //   }
  //   getRolePermintion(payload, {
  //     onSuccess: (item) => {
  //       if (item?.role_id !== null && item?.role_id !== undefined) {
  //         setRolePermission(item)
  //         localStorage.setItem("employee_Id", (item?.employee_id))
  //         if (auth) {
  //           navigate("/calibration/report-performance/dashboard")
  //         }
  //       }
  //       else {
  //         localStorage.removeItem("employee_Id");
  //       }
  //     }, onError: (error) => {
  //       localStorage.removeItem("employee_Id");
  //       if (error?.response?.status === 400 && error?.response?.data === "User Not Found") {
  //         navigate("/user-not-found")
  //       }
  //     }
  //   })
  // }

  // // useEffect(() => {
  // //   if (employee_email) {
  // //     localStorage?.setItem("Email", employee_email);
  // //   }
  // //   
  // // }, [employee_email]);

  // useEffect(() => {

  //   if (auth !== null) {

  //     const requestOptions = {
  //       method: 'POST',
  //       headers: { 'Content-Type': 'application/json' },
  //       body: JSON.stringify({ 'authorisation_id': auth })
  //     };
  //     fetch('http://172.30.8.27/shimanov2/sadmin/authentication.php', requestOptions)
  //       .then(response => response.json())
  //       .then(data => {
  //         console.log(data);
  //         if (data?.status != "true") {
  //           navigate("/user-not-found")
  //           return;
  //         }
  //         else {
  //           console.log("localdataset");
  //           localStorage?.setItem("Email", data?.customer_email)
  //           setinitial(true)
  //         }
  //       });
  //   }
  //   else {
     
  //     if (!localStorage.getItem("Email")) {
  //       localStorage.removeItem("employee_Id");
  //       navigate("/user-not-found")
  //     }
  //     else{
  //       setinitial(true)
  //     }
  //   }
  //   
  // }, []);

  // useEffect(() => {
 
  //   if (initial && localStorage.getItem("Email")) {
  //     fetchPermissionDetails();
  //   }
  //   
  // }, [initial]);



  // //Logout function Start
  // const [logout, setLogout] = useState(false);
  // const timeout = 900000
  // useEffect(() => {
  //   let timer;

  //   const resetTimer = () => {
  //     clearTimeout(timer);
  //     timer = setTimeout(() => setLogout(true), timeout);
  //   };

  //   const events = [
  //     'load',
  //     'mousemove',
  //     'mousedown',
  //     'click',
  //     'scroll',
  //     'keypress'
  //   ];

  //   const resetEvents = () => {
  //     events.forEach(event => {
  //       window.addEventListener(event, resetTimer);
  //     });
  //   };

  //   resetTimer();
  //   resetEvents();

  //   return () => {
  //     clearTimeout(timer);
  //     events.forEach(event => {
  //       window.removeEventListener(event, resetTimer);
  //     });
  //   };
  // }, [timeout]);

  // useEffect(() => {
  //   if (logout) {
  //     message.warning('Session Time Out! Please ReLogin.');


  //     // navigate("/user-not-found")

  //     localStorage?.clear();
  //     window.location.href = 'http://172.30.8.27/shimanov2/sadmin/index.php';
  //     console.log('User has been logged out due to inactivity');
  //   }
  // }, [logout]);
  //Logout function end

  return (
    <Suspense fallback={<div className="loader-img"><Image name="mainLoader2.gif" /></div>}>
      {loading ? <div className="loader-img"><Image className="mainLoader" name="mainLoader2.gif" /></div> :
        <permissionContext.Provider value={rolePermission}>
          <Routes>
            <Route element={<AppLayout />}>
              <Route exact path="/" element={<Login />} />
              {/* <Route path="/calibration/report-performance/dashboard" element={<Dashboard />} /> */}
              <Route path="/login" element={<Login />} />
              <Route path="/register" element={<SignUp />} />
              <Route path="/dashboard" element={<Dashboard />} />
              <Route path="/nfc" element={<Nfc />} />
              {/* <Route path="/calibration/report-performance/trend" element={<Trend />} />
              <Route path="/calibration/report-performance/report" element={<Reporting />} />
              <Route path="/calibration/report-performance/report/:id" element={<Detailedreport />} />

              <Route path="/calibration/task-submission/submit-task-completion" element={<CompletedTask />} />
              <Route path="/calibration/task-submission/rejected-task" element={<RejectTask />} />

              <Route path="/calibration/task-certification" element={<TaskCertification />} />

              <Route path="/calibration/system-configuration/activity-log" element={<ActivityLog />} />
              <Route path="/calibration/system-configuration/user-management" element={<UserManagement />} />
              <Route path="/calibration/system-configuration/department-management" element={<DepartmentManagement />} />
              <Route path="/calibration/system-configuration/task-management" element={<TaskManagement />} />
              <Route path="/calibration/system-configuration/equipment-management" element={<Navigate to="/calibration/system-configuration/equipment-management/active" />} />
              <Route path="/calibration/system-configuration/equipment-management/active" element={<ActiveEquipment />} />
              <Route path="/calibration/system-configuration/equipment-management/inactive" element={<InactiveEquipment />} />
              <Route path="/calibration/system-configuration/schedule-management" element={<ScheduleManagement />} />
              <Route path="/calibration/system-configuration/notification-management" element={<NotificationManagement />} />
              <Route path="/calibration/system-configuration/report-template-version" element={<TemplateVersion />} /> */}
            </Route>
            <Route path="/user-not-found" element={<UserNotFound />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </permissionContext.Provider>}
    </Suspense>
  );
}

export default App;
